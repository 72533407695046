import React, { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./Invoice.module.scss";

import Logo from "../../assets/images/logo/omega-logo.png";

import { dateFormat } from "../../utils/Utils";

import { getInboundAwbByIdAPI } from "../../api-services/inboundAWB.api";

const InboundAwbInvoice = (): ReactElement => {
  const { id } = useParams();

  const [shipmentDetails, setShipmentDetails] = useState<any>();

  useEffect(() => {
    getInboundAwbById();
  }, []);

  const getInboundAwbById = (): void => {
    getInboundAwbByIdAPI(id || "").then((res: { payload: any }) => {
      setShipmentDetails(res.payload);
    });
  };

  const getAmount = (): number => {
    const chargeableWeight = parseFloat(
      shipmentDetails?.chargeableWeight || ""
    );
    const rate = parseFloat(shipmentDetails?.ratePerKg || 0);
    const gst = parseFloat(shipmentDetails?.gst || 0);
    return chargeableWeight * rate + chargeableWeight * rate * (gst / 100);
  };

  const getIgstCalculation = (): number => {
    const chargeableWeight = parseFloat(
      shipmentDetails?.chargeableWeight || ""
    );
    const rate = parseFloat(shipmentDetails?.ratePerKg || 0);
    const gst = parseFloat(shipmentDetails?.gst || 0);
    const totalAmt = chargeableWeight * rate + chargeableWeight * rate * (gst / 100);

    const percentage = (totalAmt * 18) / 100;
    return percentage;
  };

  const getTotalForSingleItem = (): number => {
    const chargeableWeight = parseFloat(
      shipmentDetails?.chargeableWeight || ""
    );
    const rate = parseFloat(shipmentDetails?.ratePerKg || 0);
    const gst = parseFloat(shipmentDetails?.gst || 0);
    const totalAmt = chargeableWeight * rate + chargeableWeight * rate * (gst / 100);

    const percentage = (totalAmt * 18) / 100;
    return (totalAmt + percentage);

  };

  
  const calculateTotalAmount = (amt:number):number=>{
    const percentage = (amt * 18) / 100;
    const totalAmt = amt + percentage;
    return totalAmt;
  }

  return (
    <div className="content">
      <div className="card">
        <div className="row">
          <div className="col-3 content-center">
            <img src={Logo} alt="logo" height="50" />
          </div>

          <div className="col-6 text-center">
            <p className={styles["header-text"]}>OMEGA ENTERPRISES</p>
            <p className={styles["sub-header-text"]}>
              Domestic Cargo Terminal - Regulated Agent
            </p>
            <p className={styles["sub-header-text"]}>
              Visakhapatnam International Airport
            </p>
            <p className={styles["sub-header-text"]}>
              Andhra Pradesh, India - 530009
            </p>
            <p className={styles["sub-header-text"]}>Ph No : 0891 2940603</p>
            <p className={styles["sub-header-text"]}>GST No: 37AFCPR4510F1ZO</p>
          </div>
        </div>

        <hr className="my-1" />

        <p className={`${styles["body-header-text"]} text-center`}>
          TAX INVOICE - INBOUND SHIPMENT
        </p>

        <div className="row border">
          <div className="col-6 border-right">
            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>AWB No:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.airWaybillNumber}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Total No Of Pcs:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.totalNoOfPackages}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Origin:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.airportOfDeparture}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Destination:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.airportOfDestination}
                </p>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Description:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.description}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Gross Weight:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.grossWeight}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Chargeable Weight:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.chargeableWeight}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Flight No & Date:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.flightList.map(
                    (flight: any, flightIdx: number) => (
                      <span key={flightIdx} style={{ display: "block" }}>
                        {flight.flightNo} / {flight.date.split("T")[0]}
                      </span>
                    )
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 border-top">
            <div className="row">
              <div className="col-6 text-center border-right">
                <p className={styles["body-header-text"]}>Details of Agent</p>
              </div>

              <div className="col-6 text-center">
                <p className={styles["body-header-text"]}>Details of Shipper</p>
              </div>
            </div>
          </div>

          <div className="col-12 border-top">
            <div className="row">
              <div className="col-6 border-right">
                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>Customer Name:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.consigneeDetails?.name}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>Agency Code:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.agentCode}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      Name of Agency:
                    </p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.agentCode}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>GST No:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.consigneeDetails?.gst}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>PAN No:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.consigneeDetails?.pan}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>Shipper:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.shipperDetails?.name}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>Address:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.shipperDetails?.blockNo +
                        " " +
                        shipmentDetails?.shipperDetails?.locality +
                        " " +
                        shipmentDetails?.shipperDetails?.town +
                        " " +
                        shipmentDetails?.shipperDetails?.state +
                        " " +
                        shipmentDetails?.shipperDetails?.pinCode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 p-0">
            <div className={`${styles["invoice-table"]} table-responsive`}>
              <table className="w-100">
                <thead>
                  <tr>
                    <td rowSpan={2}>Sl. No</td>
                    <td rowSpan={2}>Charge Description</td>
                    <td rowSpan={2}>HSN Code</td>
                    <td rowSpan={2}>Rate</td>
                    <td rowSpan={2}>Quantity</td>
                    <td rowSpan={2}>Amount</td>
                    <td colSpan={2}>IGST</td>
                    <td colSpan={2}>CGST</td>
                    <td colSpan={2}>SGST</td>
                    <td rowSpan={2}>Total</td>
                  </tr>
                  <tr>
                    <td>Rate</td>
                    <td>Amount</td>
                    <td>Rate</td>
                    <td>Amount</td>
                    <td>Rate</td>
                    <td>Amount</td>
                  </tr>
                </thead>
                <tbody>
                  {shipmentDetails?.packageList.map(
                    (item: any, itemIdx: number) => (
                      <tr key={itemIdx}>
                        <td>{itemIdx + 1}</td>
                        <td>{shipmentDetails?.description}</td>
                        <td>{""}</td>
                        <td>{shipmentDetails?.ratePerKg}</td>
                        <td>{item?.noOfPcs}</td>
                        <td>{getAmount().toFixed(2)}</td>
                        <td>18%</td>
                        <td>{getIgstCalculation().toFixed(2)}</td>
                        <td>9%</td>
                        <td>0.00</td>
                        <td>9%</td>
                        <td>0.00</td>
                        <td>{getTotalForSingleItem()}</td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td>Total</td>
                    <td />
                    <td>
                      {Math.round(parseFloat(shipmentDetails?.totalAmount))}
                    </td>
                    <td />
                    <td>0.00</td>
                    <td />
                    <td>0.00</td>
                    <td />
                    <td>0.00</td>
                    <td>
                      {calculateTotalAmount(parseFloat(shipmentDetails?.totalAmount))}
                      {/* {Math.round(parseFloat(shipmentDetails?.totalAmount))} */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {shipmentDetails?.packageList.length > 0 && (
          <div className="row mt-5">
            <p className={styles["header-text"]}>LOCATION INFO</p>
            <div className="col-12 p-0">
              <div className={`${styles["invoice-table"]} table-responsive`}>
                <table className="w-100">
                  <thead>
                    <tr>
                      <th className="fw-bold">Warehouse</th>
                      <th className="fw-bold">Location </th>
                      <th className="fw-bold">No of Pcs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shipmentDetails.packageList.map(
                      (data: any, ind: number) => (
                        <tr key={ind}>
                          <td>{data.warehouse}</td>
                          <td>{data.locationDetails.location}</td>
                          <td>{data.noOfPcs}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {/*<div className="row mt-3">
          <div className="col-12">Prepared by:</div>
          <div className="col-3">Staff No: {shipmentDetails?.staffId}</div>
          <div className="col-3">Staff Name: {shipmentDetails?.staffName}</div>
        </div>*/}

        <div className="row mt-4">
          <div className="col-md-12 text-right">
            <button
              className="app-btn app-btn-primary"
              onClick={() => window.print()}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboundAwbInvoice;

import { getRequest, HandlerResponse } from "./api";

export const getReportAPI = async (module: string,fromDate:string,toDate:string): Promise<any> => {
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/generate/excel?module=${module}&fromDate=${fromDate}&toDate=${toDate}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem(`${process.env.REACT_APP_NAME}_TOKEN`)}`,
        },
    }).then((res) => {

            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
            return res; // If responseType is 'text'
        })
        .catch((error) => {
            console.error(`Failed to fetch ${URL}:`, error);
        });
};
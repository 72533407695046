import { IInboundManifestPayload } from '../interfaces/inboundManifest.interface';
import { deleteRequest, getRequest, HandlerResponse, postRequest, putRequest } from './api';
import { ObjToQueryString } from '../utils/Utils';

export const createInboundManifestAPI = async (payload: IInboundManifestPayload): Promise<any> => {
  return postRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/create-inbound-manifest`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

export const getInboundManifestAPI = async (): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/get-all-inbound-manifest`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getInboundManifestByIdAPI = async (id: string): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/get-inbound-manifest-info/${id}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const updateInboundManifestAPI = async (id: string, payload: IInboundManifestPayload): Promise<any> => {
  return putRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/update-inbound-manifest-info/${id}`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

export const checkInboundAwbAPI = async (queryParams?: any): Promise<any> => {
  const queryString = ObjToQueryString(queryParams);
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/check-inbound-awb${queryString}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const deleteInboundManifestAPI = async (uuid: string): Promise<any> => {
  return deleteRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/inbound-manifest/${uuid}`,)
    .then((resp: any) => HandlerResponse(resp));
};

import React, { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./Invoice.module.scss";

import Logo from "../../assets/images/logo/omega-logo.png";

import { dateFormat } from "../../utils/Utils";

import { IShipment } from "../../interfaces/shipment.interface";
import { getShipmentByIdAPI } from "../../api-services/shipment.api";

const Invoice = (): ReactElement => {
  const { id } = useParams();

  const [shipmentDetails, setShipmentDetails] = useState<IShipment | undefined>(
    undefined
  );

  useEffect(() => {
    getShipmentById();
  }, []);

  const getShipmentById = (): void => {
    getShipmentByIdAPI(id || "").then((res: { payload: IShipment }) => {
      setShipmentDetails(res.payload);
    });
  };

  const getAmount = (data: any): number => {
    const grossWeight = parseFloat(shipmentDetails?.grossWeight || "");
    const rate = shipmentDetails?.ratePerKg || 0;
    const chargeableWeight =
      ((parseFloat(data.length) *
        parseFloat(data.width) *
        parseFloat(data.height)) /
        6000) *
      parseFloat(data.quantity);
    const weight =
      grossWeight > chargeableWeight ? grossWeight : chargeableWeight;
    const totalAmt = weight * rate;
    return totalAmt;
  };

  const getIgstCalculation = (data: any): number => {
    const grossWeight = parseFloat(shipmentDetails?.grossWeight || "");
    const rate = shipmentDetails?.ratePerKg || 0;
    const chargeableWeight =
      ((parseFloat(data.length) *
        parseFloat(data.width) *
        parseFloat(data.height)) /
        6000) *
      parseFloat(data.quantity);
    const weight =
      grossWeight > chargeableWeight ? grossWeight : chargeableWeight;
    const totalAmt = weight * rate;
    const percentage = (totalAmt * 18) / 100;
    return percentage;
  };
  const getTotalForSingleItem = (data: any): number => {
    const grossWeight = parseFloat(shipmentDetails?.grossWeight || "");
    const rate = shipmentDetails?.ratePerKg || 0;
    const chargeableWeight =
      ((parseFloat(data.length) *
        parseFloat(data.width) *
        parseFloat(data.height)) /
        6000) *
      parseFloat(data.quantity);
    const weight =
      grossWeight > chargeableWeight ? grossWeight : chargeableWeight;
    const amt = weight * rate;
    const percentage = (amt * 18) / 100;
    const totalAmt = amt + percentage;
    return totalAmt;
  };

  const calculateTotalAmount = (amt:number):number=>{
    const percentage = (amt * 18) / 100;
    const totalAmt = amt + percentage;
    return totalAmt;
  }
  return (
    <div className="content">
      <div className="card">
        <div className="row">
          <div className="col-3 content-center">
            <img src={Logo} alt="logo" height="50" />
          </div>

          <div className="col-6 text-center">
            <p className={styles["header-text"]}>OMEGA ENTERPRISES</p>
            <p className={styles["sub-header-text"]}>
              Domestic Cargo Terminal - Regulated Agent
            </p>
            <p className={styles["sub-header-text"]}>
              Visakhapatnam International Airport
            </p>
            <p className={styles["sub-header-text"]}>
              Andhra Pradesh, India - 530009
            </p>
            <p className={styles["sub-header-text"]}>Ph No : 0891 2940603</p>
            <p className={styles["sub-header-text"]}>GST No: 37AFCPR4510F1ZO</p>
          </div>
        </div>

        <hr className="my-1" />

        <p className={`${styles["body-header-text"]} text-center`}>
          TAX INVOICE - OUTBOUND SHIPMENT
        </p>

        <div className="row border">
          <div className="col-6 border-right">
            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>AWB No:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.airWaybillNumber}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Total No Of Pcs:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.totalNoOfPackages}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Origin:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.airportOfDeparture}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Destination:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.airportOfDestination}
                </p>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Description:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.description}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Gross Weight:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.grossWeight}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Chargeable Weight:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.chargeableWeight}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <p className={styles["body-header-text"]}>Flight No & Date:</p>
              </div>
              <div className="col-6">
                <p className={styles["body-header-text"]}>
                  {shipmentDetails?.flightList.map(
                    (flight, flightIdx: number) => (
                      <span key={flightIdx} className="d-block">
                        {flight.flightDetails?.flightNo} /{" "}
                        {dateFormat(flight.shippingDate)}
                      </span>
                    )
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 border-top">
            <div className="row">
              <div className="col-6 text-center border-right">
                <p className={styles["body-header-text"]}>Details of Agent</p>
              </div>

              <div className="col-6 text-center">
                <p className={styles["body-header-text"]}>Details of Shipper</p>
              </div>
            </div>
          </div>

          <div className="col-12 border-top">
            <div className="row">
              <div className="col-6 border-right">
                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>Customer Name:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.consigneeDetails?.name}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>Agency Code:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.agentCode}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      Name of Agency:
                    </p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.agentCode}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>GST No:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.consigneeDetails?.gst}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>PAN No:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.consigneeDetails?.pan}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>Shipper:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.shipperDetails?.name}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>Address:</p>
                  </div>
                  <div className="col-6">
                    <p className={styles["body-header-text"]}>
                      {shipmentDetails?.shipperDetails?.blockNo +
                        " " +
                        shipmentDetails?.shipperDetails?.locality +
                        " " +
                        shipmentDetails?.shipperDetails?.town +
                        " " +
                        shipmentDetails?.shipperDetails?.state +
                        " " +
                        shipmentDetails?.shipperDetails?.pinCode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 p-0">
            <div className={`${styles["invoice-table"]} table-responsive`}>
              <table className="w-100">
                <thead>
                  <tr>
                    <td rowSpan={2}>Sl. No</td>
                    <td rowSpan={2}>Charge Description</td>
                    <td rowSpan={2}>HSN Code</td>
                    <td rowSpan={2}>Rate</td>
                    <td rowSpan={2}>Quantity</td>
                    <td rowSpan={2}>Amount</td>
                    <td colSpan={2}>IGST</td>
                    <td colSpan={2}>CGST</td>
                    <td colSpan={2}>SGST</td>
                    <td rowSpan={2}>Total</td>
                  </tr>
                  <tr>
                    <td>Rate</td>
                    <td>Amount</td>
                    <td>Rate</td>
                    <td>Amount</td>
                    <td>Rate</td>
                    <td>Amount</td>
                  </tr>
                </thead>
                <tbody>
                  {shipmentDetails?.packageList.map((item, itemIdx: number) => (
                    <tr key={itemIdx}>
                      <td>{itemIdx + 1}</td>
                      <td>{shipmentDetails?.description}</td>
                      <td>{""}</td>
                      <td>{shipmentDetails?.ratePerKg}</td>
                      <td>{item?.quantity}</td>
                      <td>{getAmount(item).toFixed(2)}</td>
                      <td>18%</td>
                      <td>{getIgstCalculation(item).toFixed(2)}</td>
                      <td>9%</td>
                      <td>0.00</td>
                      <td>9%</td>
                      <td>0.00</td>
                      <td>{getTotalForSingleItem(item).toFixed(2)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td>Total</td>
                    <td />
                    <td>
                      {shipmentDetails?.amountToBeCollected !== undefined
                        ? Math.round(
                            parseFloat(
                              shipmentDetails.amountToBeCollected.toFixed(2)
                            )
                          )
                        : "N/A"}{" "}
                    </td>
                    <td />
                    <td>0.00</td>
                    <td />
                    <td>0.00</td>
                    <td />
                    <td>0.00</td>
                    <td>
                      {shipmentDetails?.amountToBeCollected !== undefined
                        ? 
                        calculateTotalAmount(shipmentDetails.amountToBeCollected).toFixed(2)
                        
                        : "N/A"}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12 text-right">
            <button
              className="app-btn app-btn-primary"
              onClick={() => window.print()}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;

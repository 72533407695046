import { IOutboundManifestPayload } from '../interfaces/outboundManifest.interface';
import { deleteRequest, getRequest, HandlerResponse, postRequest, putRequest } from './api';

export const createOutboundManifestAPI = async (payload: IOutboundManifestPayload): Promise<any> => {
  return postRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/create-outbound-manifest`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

export const getOutboundManifestAPI = async (): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/get-all-outbound-manifest`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getOutboundManifestByIdAPI = async (id: string): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/get-outbound-manifest-info/${id}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const updateOutboundManifestAPI = async (id: string, payload: IOutboundManifestPayload): Promise<any> => {
  return putRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/update-outbound-manifest-info/${id}`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

export const deleteOutboundManifestAPI = async (uuid: string): Promise<any> => {
  return deleteRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/outbound-manifest/${uuid}`,)
    .then((resp: any) => HandlerResponse(resp));
};

import React, { useState } from "react";
import Select from "react-select";
import { getReportAPI } from "../../api-services/report.api";
import Calendar from "react-calendar";

// Define the option type
interface ISelectDropdown {
  label: string;
  value: string;
}
//MODULE LIST
const MODULE_LIST: ISelectDropdown[] = [
  {
    label: "Outbound Awb",
    value: "OUTBOUND_AWB",
  },
  {
    label: "Inbound Awb",
    value: "INBOUND_AWB",
  },
  {
    label: "Outbound cutomer invoice",
    value: "OUTBOUND_CUSTOMER_INVOICE",
  },
  {
    label: "Inbound cutomer invoice",
    value: "INBOUND_CUSTOMER_INVOICE",
  },
];

interface IFrom {
  module: ISelectDropdown[];
  fromDate: string;
  toDate: string;
}

const Report = () => {
  const [form, setForm] = useState<IFrom>({
    module: [],
    fromDate: "",
    toDate: "",
  });

  const [formErr, setFormErr] = useState<any>({
    fromDateErr: "",
    toDateErr: "",
  });

  const updateForm = (e: any, type: string) => {
    setForm({ ...form, [type]: e });
  };

  const dateChangeHandler = (e: any, type: string) => {
    setForm({ ...form, [type]: e.target.value });
    setFormErr({
      fromDateErr: "",
      toDateErr: "",
    });
  };

  const formValidity = (): boolean => {
    return form.module.length > 0;
  };
  const downloadreporthandler = () => {
    if (form.fromDate === "" && form.toDate !== "") {
      setFormErr({ ...formErr, ["fromDateErr"]: "Select From Date" });
      return;
    }
    if (form.fromDate !== "" && form.toDate === "") {
      setFormErr({ ...formErr, ["toDateErr"]: "Select To Date" });
      return;
    }
    if (new Date(form.fromDate) > new Date(form.toDate)) {
      setFormErr({
        ...formErr,
        ["toDateErr"]: "To date cannot be earlier than From date",
      });
      return;
    }
    const module = form.module[0].value;
    const fromDate = form.fromDate;
    const toDate = form.toDate;
    getReportAPI(module, fromDate, toDate).then((res: any) => {
      if (res) {
        downloadFile2(res);
      }
    });
  };

  const downloadFile2 = async (response: Response) => {
    const blob = await response.blob(); // Convert response body to a Blob

    // Extract Content-Disposition header to get the filename
    const disposition = response.headers.get("Content-Disposition");
    let fileName = `${form.module[0].value}_report.csv`; // Default filename

    if (disposition) {
      const match = disposition.match(/filename="?(.+?)"?$/);
      if (match && match[1]) {
        fileName = match[1];
      }
    }

    // Create a link to download the file
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", fileName);
    link.click();
  };

  return (
    <div className="content">
      <div className="card pb-4">
        <div className="row">
          <div className="col-md-12">
            <div className="card-title mb-3">Report</div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-3 mb-3">
                <label htmlFor="module-code" className="form-label-text">
                  Select Module <span className="text-danger">*</span>
                </label>
                <Select
                  id="module-code"
                  options={MODULE_LIST}
                  isSearchable
                  placeholder="Select module"
                  onChange={(e) => updateForm([e], "module")}
                  value={form.module}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="from-date" className="form-label-text">
                  From Date
                </label>
                <input
                  id="from-date"
                  type="date"
                  placeholder="Select form date"
                  className="form-input-field w-100"
                  onChange={(e) => dateChangeHandler(e, "fromDate")}
                  value={form.fromDate}
                />
                {formErr.fromDateErr && (
                  <p className="form-label-text text-danger">
                    {formErr.fromDateErr}
                  </p>
                )}
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="to-date" className="form-label-text">
                  To Date
                </label>
                <input
                  id="to-date"
                  type="date"
                  placeholder="Select to date"
                  className="form-input-field w-100"
                  onChange={(e) => dateChangeHandler(e, "toDate")}
                  value={form.toDate}
                />
                {formErr.toDateErr && (
                  <p className="form-label-text text-danger">
                    {formErr.toDateErr}
                  </p>
                )}
              </div>
              <div className="col-3">
                <button
                  className="app-btn app-btn-primary mt-4"
                  onClick={downloadreporthandler}
                  disabled={!formValidity()}
                >
                  Download Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;

import React from 'react';
import { Navigate } from 'react-router-dom';

import OutboundAwbPage from '../pages/outbound-awb/OutboundAwbPage';
import Invoice from '../pages/outbound-awb/Invoice';
import EditOutboundAwbPage from '../pages/outbound-awb/EditOutboundAwbPage';
import Login from '../pages/auth/Login';
import FlightScreen from '../pages/flights/FlightScreen';
import CustomerScreen from '../pages/customer/CustomerScreen';
import AddCustomerScreen from '../pages/customer/AddCustomerScreen';
import SettingScreen from '../pages/setting/SettingScreen';
import AddUser from '../pages/setting/AddUser';
import AddFlightScreen from '../pages/setting/AddFlightScreen';
import OutboundManifestPage from '../pages/outbound-manifest/OutboundManifestPage';
import EditOutboundManifestPage from '../pages/outbound-manifest/EditOutboundManifestPage';
import OutboundManifestInvoicePage from '../pages/outbound-manifest/OutboundManifestInvoicePage';
import InboundManifestPage from '../pages/inbound-manifest/InboundManifestPage';
import EditInboundManifestPage from '../pages/inbound-manifest/EditInboundManifestPage';
import InboundAwbPage from '../pages/inbound-awb/InboundAwbPage';
import EditInboundAwbPage from '../pages/inbound-awb/EditInboundAwbPage';
import InboundAwbInvoice from '../pages/inbound-awb/InboundAwbInvoice';
import OutboundCustomerInvoicePage from '../pages/outbound-customer-invoice/OutboundCustomerInvoicePage';
import EditOutboundCustomerInvoicePage from '../pages/outbound-customer-invoice/EditOutboundCustomerInvoicePage';
import OutboundTaxInvoice from '../pages/outbound-customer-invoice/OutboundTaxInvoice';
import InboundCustomerInvoicePage from '../pages/inbound-customer-invoice/InboundCustomerInvoicePage';
import EditInboundCustomerInvoicePage from '../pages/inbound-customer-invoice/EditInboundCustomerInvoicePage';
import InboundTaxInvoice from '../pages/inbound-customer-invoice/InboundTaxInvoice';
import InboundManifestInvoicePage from '../pages/inbound-manifest/InboundManifestInvoicePage';
import Report from '../pages/report/Report';

const authProtectedRoutes = [
  {
    path: '/outbound-awb',
    element: <OutboundAwbPage/>,
  },
  {
    path: '/invoice/:id',
    element: <Invoice/>,
  },
  {
    path: '/add-outbound-awb',
    element: <EditOutboundAwbPage/>,
  },
  {
    path: '/update-shipment/:id',
    element: <EditOutboundAwbPage/>,
  },
  {
    path: '/flights',
    element: <FlightScreen/>,
  },
  {
    path: '/customer',
    element: <CustomerScreen/>,
  },
  {
    path: '/add-customer',
    element: <AddCustomerScreen/>,
  },
  {
    path: '/update-customer/:id',
    element: <AddCustomerScreen/>,
  },
  {
    path: '/setting',
    element: <SettingScreen/>,
  },
  {
    path: '/add-user',
    element: <AddUser/>,
  },
  {
    path: '/edit-user/:id',
    element: <AddUser/>,
  },
  {
    path: '/add-flight/:type',
    element: <AddFlightScreen/>
  },
  {
    path: '/outbound-manifest',
    element: <OutboundManifestPage/>
  },
  {
    path: '/add-outbound-manifest',
    element: <EditOutboundManifestPage/>
  },
  {
    path: '/update-outbound-manifest/:id',
    element: <EditOutboundManifestPage/>
  },
  {
    path: '/outbound-manifest-invoice/:id',
    element: <OutboundManifestInvoicePage/>
  },
  {
    path: '/inbound-manifest',
    element: <InboundManifestPage/>
  },
  {
    path: '/add-inbound-manifest',
    element: <EditInboundManifestPage/>
  },
  {
    path: '/update-inbound-manifest/:id',
    element: <EditInboundManifestPage/>
  },
  {
    path: '/inbound-manifest-invoice/:id',
    element: <InboundManifestInvoicePage/>
  },
  {
    path: '/inbound-awb',
    element: <InboundAwbPage/>
  },
  {
    path: '/add-inbound-awb',
    element: <EditInboundAwbPage/>
  },
  {
    path: '/update-inbound-awb/:id',
    element: <EditInboundAwbPage/>
  },
  {
    path: '/update-flight/:type/:id',
    element: <AddFlightScreen/>,
  },
  {
    path: '/outbound-customer-invoice',
    element: <OutboundCustomerInvoicePage/>,
  },
  {
    path: '/add-outbound-customer-invoice',
    element: <EditOutboundCustomerInvoicePage/>,
  },
  {
    path: '/update-outbound-customer-invoice/:id',
    element: <EditOutboundCustomerInvoicePage/>,
  },
  {
    path: '/outbound-tax-invoice/:id',
    element: <OutboundTaxInvoice/>,
  },
  {
    path: '/inbound-awb-invoice/:id',
    element: <InboundAwbInvoice/>
  },
  {
    path: '/inbound-customer-invoice',
    element: <InboundCustomerInvoicePage/>,
  },
  {
    path: '/add-inbound-customer-invoice',
    element: <EditInboundCustomerInvoicePage/>,
  },
  {
    path: '/update-inbound-customer-invoice/:id',
    element: <EditInboundCustomerInvoicePage/>,
  },
  {
    path: '/inbound-tax-invoice/:id',
    element: <InboundTaxInvoice/>,
  },
  {
    path:'/report',
    element:<Report/>
  }
];

const nonProtectedRoutes = [
  {
    path: '/sign-in',
    element: <Login/>,
  },
  {
    path: '/',
    element: <Navigate to="/sign-in" replace/>,
  },
];

export { authProtectedRoutes, nonProtectedRoutes };

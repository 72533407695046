import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  deleteInboundManifestAPI,
  getInboundManifestAPI,
} from "../../api-services/inboundManifest.api";
import { dateFormat, dateTimeFormat } from "../../utils/Utils";
import { useAlertStore } from "../../store/store";

const InboundManifestPage = (): ReactElement => {
  const navigate = useNavigate();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>("");
  const updateAlertText = useAlertStore((state) => state.updateAlertText);

  useEffect(() => {
    getInboundManifest();
  }, []);

  const getInboundManifest = (): void => {
    getInboundManifestAPI().then((res: { payload: any[] }) => {
      if (res && searchKey) {
        const filteredData = res.payload.filter((data) =>
          data.flightDetails.flightNo.includes(searchKey)
        );
        setDataSource(filteredData);
      } else {
        setDataSource(res.payload);
      }
    });
  };

  const totalChargeableWeight = (data: any) => {
    const totalWeight = data.Local.concat(data.Transit).reduce(
      (acc: any, value: any) => {
        // Ensure that value.chWt is a number before parsing
        const weight = parseFloat(value.chWt) || 0;
        return acc + weight;
      },
      0
    );

    return totalWeight.toFixed(2); // Format the result to 2 decimal places
  };

  const deleteManifest = (uuid: string) => {
    deleteInboundManifestAPI(uuid).then((res) => {
      if (res) {
        updateAlertText("Shipment deleted successfully", "success");
        getInboundManifest();
      }
    });
  };

  return (
    <div className="content">
      <div className="card">
        <div className="row">
          <div className="col-md-4">
            <div className="input-group flex-nowrap">
              <input
                type="text"
                className="form-control"
                placeholder="Search Flight Number"
                aria-label="awbNumber"
                aria-describedby="addon-wrapping"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <span
                className="input-group-text"
                id="addon-wrapping"
                onClick={getInboundManifest}
              >
                <i className="fa fa-search cursor-pointer" />
              </span>
            </div>
          </div>
          <div className="col-md-8 text-right">
            <button
              className="app-btn app-btn-primary"
              onClick={() => navigate("/add-inbound-manifest")}
            >
              Add Inbound Manifest
            </button>
          </div>

          <div className="col-md-12">
            <div className="table-responsive mt-3">
              <table className="mb-0 w-100">
                <thead>
                  <tr>
                    <th>Sl. No</th>
                    <th>Flight No</th>
                    <th>Date</th>
                    <th>Owner/Operator</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Total No.of AWBs</th>
                    <th>Total Pcs</th>
                    <th>Gross Weight</th>
                    <th>Chargeable Weight</th>
                    <th>Created Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dataSource.map((item, shipmentIdx: number) => (
                    <tr key={shipmentIdx}>
                      <td>{shipmentIdx + 1}</td>
                      <td>{item.flightDetails.flightNo}</td>
                      <td>{dateFormat(item.date.split("T")[0])}</td>
                      <td>{item.owner}</td>
                      <td>{item.pointOfLoading}</td>
                      <td>{item.pointOfUploading}</td>
                      <td>{item.totalAwb}</td>
                      <td>{item.totalPcs}</td>
                      <td>{item.grossWt}</td>
                      <td>{totalChargeableWeight(item)}</td>
                      <td>{dateTimeFormat(item.updatedAt)}</td>
                      <td className="text-center">
                        <i
                          className="fa fa-pencil-alt text-danger cursor-pointer me-3"
                          onClick={() =>
                            navigate(`/update-inbound-manifest/${item.uuid}`)
                          }
                        />
                        <i
                          className="fa fa-file-invoice text-primary cursor-pointer me-3"
                          onClick={() =>
                            navigate(`/inbound-manifest-invoice/${item.uuid}`)
                          }
                        />
                        <i
                          className="fa fa-trash text-danger cursor-pointer"
                          onClick={() => deleteManifest(item.uuid)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboundManifestPage;
